import "../style.scss"
import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";

const unityContext = new UnityContext({
  loaderUrl: "translator/translator.loader.js",
  dataUrl: "translator/translator.data",
  frameworkUrl: "translator/translator.framework.js",
  codeUrl: "translator/translator.wasm",
});

unityContext.on("CopyOutputTextToClipboard", function (outputText) {
  navigator.clipboard.writeText(outputText)
});

unityContext.on("DisplayFullScreen", function () {
  unityContext.setFullscreen(true);
});

class Translator extends React.Component {
	render(){
	return (
		<div className="center">
			<Unity className="unity" unityContext={unityContext} tabIndex={1}/>
		</div>
	)
  }
}

export default Translator;