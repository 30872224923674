import "../style.scss";
import image from "../images/lanara.png";
import React from "react";

class Logo extends React.Component {
  render() {
	return (
	  <div className="center">
		  <img src={image} alt="Lanara the Sneakbold"/>
		  <div className="footer">
		  (Lanara, the Sneakbold)
		  </div>
      </div>
	)
  }
}

export default Logo;