import React from "react";
import ReactDOM from "react-dom";
import View from "./classes/view.js";
import WebFont from 'webfontloader';
WebFont.load({
  google: {
    families: ['Roboto', 'sans-serif']
  }
});

ReactDOM.render(<View />, document.getElementById('root'));