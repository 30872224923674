import "../style.scss";
import React from "react";
import Translator from "./translator.js";
import KoboldKabana from "./koboldkabana.js";
import Divider from "./divider.js";

class View extends React.Component {
  constructor() {
    super();
      this.state = {
        section:"none"
	  }
  }

  showNone() {
	this.setState(() => ({section: "none"}));
  }

  showKoboldKabana() {
	this.setState(() => ({section: "koboldkabana"}));
  }
		
  showTranslator() {
	this.setState(() => ({section: "translator"}));
  }	
	  
  render() {
	return (
	<div>
		<div className="header">
	      Projects
	    </div>
		<div className="center">
			<Divider/>

			<p />
			
			
			<button className="projectButton koboldKabanaButton" onClick={() => this.showKoboldKabana()}></button>
			<button className="projectButton translator" onClick={() => this.showTranslator()}></button>
			
			{
			  this.state.section !== "none"
			  ? <Divider/>
			  : null
			}
		</div>
		{
          this.state.section === "koboldkabana"
		  ? <div>
				<div className="paragraphText">
				  Help the newly minted tavern-owning kobold Rela!
				  <p/>
				  Her quest? To take a broken down hole-in-the-wall from Klarp's List and turn it into the best little hangout spot in the land!
				  <p/>
				  Status: Prototype | Platform: Steam
				  <Divider/>
				</div>
				<div className="center">
				  <Divider/>
				  <KoboldKabana />
				  <Divider/>
				</div>
			</div>
			: null
		}
		{
	    this.state.section === "translator"
	    ? <div>
			<div className="paragraphText">
			  A utility program which uses dictionaries for <a target="_blank" rel="noopener noreferrer" href="https://forgottenrealms.fandom.com/wiki/Draconic_dictionary">Draconic</a>, <a target="_blank" rel="noopener noreferrer" href="https://forgottenrealms.fandom.com/wiki/Drow_dictionary">Drow</a>, and <a target="_blank" rel="noopener noreferrer" href="https://forgottenrealms.fandom.com/wiki/Orcish_dictionary">Orcish</a> to translate text! Perfect for conversing with dragons, dragonborn, of course our favorite <b>kobolds</b>, and other races too!
			  <p/>
			  Status: Prototype | Platform: Web Browser
			  </div>
			  <div>
			  <Divider/>
			  <Translator/>
			  <Divider/>
			</div>
		  </div>
		: null
		}
	  </div>
	)
  }
}

export default View;