import "../style.scss";
import image from "../images/logo.png";
import React from "react";

class Logo extends React.Component {
  render() {
	return (
		<div className="center logo">
			<img src={image} alt=""/>
		</div>
	)
  }
}

export default Logo;