import "../style.scss";
import React from "react";
import Logo from "./logo.js";
import Divider from "./divider.js";
import Header from "./header.js";
import Lanara from "./lanara.js";
import Projects from "./projects.js";

class View extends React.Component {
	
 constructor() {
    super();
    this.state = {
	  section:"about"
    }
  }

  showAbout() {
    this.setState(() => ({section: "about"}));
  }

  showProjects() {
    this.setState(() => ({section: "projects"}));
  }

  render() {
    return (
	  <div>
		<div className="headerNav">
			<button className="headerButton" onClick={() => this.showAbout()}>About</button>
			<button className="headerButton" onClick={() => this.showProjects()}>Projects</button>
		</div>
		<div className="center">
			<Logo/>
			<Divider/>
		</div>
		{/*When you select the about button show the about stuff*/}
		{
          this.state.section === "about"
            ? <div><Header /><Divider/><Lanara/></div>
            : null
        }
		
		{/*When you select the projects button show the projects area, or nothing*/}
        {
          this.state.section === "projects"
            ? <Projects />
            : null
        }
	  </div>
	)
  }
}

export default View;