import "../style.scss";
import image from "../images/koboldKabanaTitle.png";
import React from "react";

class Logo extends React.Component {
  render() {
	return (
	  <div className="center">
		<img src={image} alt=""/>
      </div>
	)
  }
}

export default Logo;