import "../style.scss";
import image from "../images/divider.png";
import React from "react";

class Divider extends React.Component {
  render() {
	return (
	  <div className="center">
        <img src={image} alt=""/>
      </div>
	)
  }
}

export default Divider;