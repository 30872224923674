import "../style.scss";
import React from "react";
class Header extends React.Component {
  render() {
	return (
	<div>
	  <div>
	    <div className="header">
		  Welcome to Sneakbold Software!
	    </div>
		
	    <div className="paragraphText">
	      We're a game development company with one core goal: to create games that are fun, intriguing, and awesome!
		  <p />
		  Select Projects to see some of the things we're digging our claws into!
	    </div>
      </div>
	</div>
	)
  }
}

export default Header;